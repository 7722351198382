import { Component, Input } from '@angular/core';
import { LastRelease, Song } from '../../@core/models/last-release.model';
import { Release } from '../../@core/models/release.model';



@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrl: './links.component.scss',

})
export class LinksComponent{
  @Input('last_release') last_release!: LastRelease | Release | Song;

  constructor(
  ) {

  }
  openUrl(url: string){
    window.open(url, '_blank');
  }
}
