import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksComponent } from './links.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material.module';


@NgModule({
  declarations: [
    LinksComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule
  ],
  exports: [
    LinksComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LinksSharedModule { }
