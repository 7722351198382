import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Release } from '../models/release.model';


@Injectable({
    providedIn: 'root'
})


export class ReleasesService {
    public releasesSubject: BehaviorSubject<Release[]> = new BehaviorSubject<Release[]>([] as Release[]);
    releases!: Release[];

    constructor(
        private http: HttpClient
    ) { }

    setReleasesSubject() {
        this.releasesSubject.next(this.releases);
    }

    setReleases(about: Release[]): void {
        this.releases = about;
        this.setReleasesSubject();
    }

    getReleasesJsonData(): Observable<any> {
        const url = `/assets/content/releases.json?nocache=${new Date().getTime()}`;
        return this.http.get<any>(url);
    }

    getReleasesObservable(): Observable<Release[]> {
        return this.releasesSubject.asObservable();
    }



}